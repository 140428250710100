import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import bbplogo from './img/bbplogo.svg'
import './index.css'

dayjs.extend(duration)

function CountdownComponent() {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  const targetDate = dayjs(new Date('2023-11-06 00:00:00'))

  const handleCountDown = ({ days, hours, minutes, seconds }) => {
    setDays(days)
    setHours(hours)
    setMinutes(minutes)
    setSeconds(seconds)
  }

  const addLeadingZeros = (value) => {
    value = String(value)
    while (value.length < 2) {
      value = '0' + value
    }
    return value
  }

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      const now = dayjs()
      const remainingTime = dayjs.duration(dayjs(targetDate).diff(now))

      if (remainingTime.asMilliseconds() < 0) {
        clearInterval(countdownInterval)
        handleCountDown({ days: 0, hours: 0, minutes: 0, seconds: 0 })
      } else {
        const days = Math.floor(remainingTime.asDays())
        const hours = remainingTime.hours()
        const minutes = remainingTime.minutes()
        const seconds = remainingTime.seconds()

        handleCountDown({ days, hours, minutes, seconds })
      }
    }, 1000)

    return () => clearInterval(countdownInterval)
  }, [targetDate])

  useEffect(() => {
    function setHeights() {
      const windowHeight = window.innerHeight
      document.querySelectorAll('.slide').forEach((element) => {
        element.style.height = `${windowHeight}px`
      })
    }

    function addSticky() {
      const scrollY = window.scrollY
      document.querySelectorAll('.slide').forEach((element) => {
        const scrollerAnchor = element.offsetTop
        if (scrollY >= scrollerAnchor) {
          element.classList.add('fix-it')
        } else {
          element.classList.remove('fix-it')
        }
      })
    }

    setHeights()
    window.addEventListener('resize', setHeights)
    window.addEventListener('scroll', addSticky)

    return () => {
      window.removeEventListener('resize', setHeights)
      window.removeEventListener('scroll', addSticky)
    }
  }, [])

  return (
    <div>
      <video muted autoPlay loop id="background">
        <source
          src={
            'https://storage.googleapis.com/bucket-mailing-comunicacion/CONTADORES%20SITIOS/bbp-anim.mp4'
          }
          type="video/mp4"
        />
      </video>
      <div className="main">
        <div id="content">
          <img src={bbplogo} alt="bbplogo"></img>
          <p>coming soon</p>
          <br />
          <section>
            <ul id="countdown">
              <li>
                <span className="days timenumbers">
                  {addLeadingZeros(days)}
                </span>
                <p className="timeRefDays timedescription">
                  {days === 1 ? 'day' : 'days'}
                </p>
              </li>
              <li>
                <span className="hours timenumbers">
                  {addLeadingZeros(hours)}
                </span>
                <p className="timeRefHours timedescription">hours</p>
              </li>
              <li>
                <span className="minutes timenumbers">
                  {addLeadingZeros(minutes)}
                </span>
                <p className="timeRefMinutes timedescription">minutes</p>
              </li>
              <li>
                <span className="seconds timenumbers yellow-text">
                  {addLeadingZeros(seconds)}
                </span>
                <p className="timeRefSeconds timedescription">seconds</p>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  )
}

export default CountdownComponent
